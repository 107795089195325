import Authenticated from "../Layout/Authenticated";
import * as cheerio from "cheerio";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, url as urlLink, axios } from "../axios";
import Pagination from "@mui/material/Pagination";
import { InfinitySpin } from "react-loader-spinner";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { format, formatDate } from "date-fns";
import { Alert, MenuItem, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/24/outline";
import { isMobile } from "react-device-detect";

const rows = [
  {
    id: 1,
  },
];

const headCells = [
  {
    id: "udyam_id",
    numeric: false,
    disablePadding: false,
    label: "Udyam ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },

  {
    id: "contact",
    numeric: false,
    disablePadding: false,
    label: "Contact",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
  // {
  //   id: "protein",
  //   numeric: true,
  //   sortable: false,
  //   disablePadding: false,
  //   label: "",
  // },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const user = useSelector((state) => state.user);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export default function AllUdyamPage({ isOpen, isClose }) {
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [query, setQuery] = useState("");
  const [selectedDistrict, setselectedDistrict] = useState("");
  const [tempData, setTempData] = useState({});
  const [isBusy, setIsbusy] = useState(true);
  const [allUdyam, setAllUdyam] = useState(false);
  const [entities, setEntities] = useState({
    data: [],
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [clipboardCopied, setClipboardCopied] = useState("");
  const [udyamNo, setUdyamNo] = useState("");
  const [officialAddress, setOfficialAddress] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [noUnits, setNoUnits] = useState(false);

  const resetSearchQuery = () => {
    setUdyamNo("");
    setOfficialAddress("");
    setOwnerName("");
    setselectedDistrict();
    setStartDate("");
    setEndDate("");
    setAllUdyam(false);
    getData(1);
  };
  const submit = (e) => {
    e.preventDefault();
    setIsbusy(true);
    setModal(false);
    let form = {
      entity_id: tempData.id,
      remarks: tempData.remarks,
      is_checked: tempData.is_checked,
      requestEditData: tempData,
    };

    if (tempData.submitEdit === true) {
      form.requestEdit = true;
    }
    setIsbusy(true);
    api
      .post("/api/check", form)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {})
      .finally(() => {
        getData(page);
        setIsbusy(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const copyToClipboard = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    setClipboardCopied(data); //
    navigator.clipboard.writeText(data);
    setTimeout(() => {
      setClipboardCopied("");
    }, 2000);
  };
  const handleCloseModal = () => {
    setModal(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, data) => {
    event.stopPropagation();
    setTempData(data);
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const openCheckModal = (e, row) => {
    e.stopPropagation();
    setTempData({ ...row, is_checked: 0 });
    setOpen(false);
    setModal(true);
  };

  const district = [
    { id: 1, name: "Aizawl" },
    { id: 2, name: "Mamit" },
    { id: 3, name: "Lunglei" },
    { id: 4, name: "Champhai" },
    { id: 5, name: "Kolasib" },
    { id: 6, name: "Serchhip" },
    { id: 7, name: "Siaha" },
    { id: 8, name: "Lawngtlai" },

    // More users...
  ];

  const navigate = useNavigate();

  const filteredDistrict =
    query === ""
      ? district
      : district.filter((district) => {
          return district.name.toLowerCase().includes(query.toLowerCase());
        });

  const getData = async (page) => {
    setIsbusy(true);
    const url = new URL(`${urlLink}/api/entity`);

    page && url.searchParams.append("page", page);
    udyamNo && url.searchParams.append("udyam_id", udyamNo);
    officialAddress &&
      url.searchParams.append("official_address", officialAddress);
    ownerName && url.searchParams.append("owner_name", ownerName);
    selectedDistrict &&
      url.searchParams.append("district", selectedDistrict.toLowerCase());
    startDate && url.searchParams.append("start_date", startDate);
    endDate && url.searchParams.append("end_date", endDate);
    url.searchParams.append("is_checked", !allUdyam);
    noUnits && url.searchParams.append("no_units", 1);

    axios
      .get(url)
      .then((response) => {
        setEntities(response.data);
        console.log(response);
      })
      .catch((err) => {
        if (err.status === 401) {
          window.alert("You are not authorized to access this page");
          window.location = "/login";
        }
      })
      .finally(() => {
        setIsbusy(false);
      });

    return 0;
  };

  const handleRemoveField = async (field, index) => {
    setTempData({
      ...tempData,
      [field]: tempData[field].filter((_, i) => i !== index),
    });
  };

  const handleAddField = (field) => {
    setTempData({
      ...tempData,
      [field]: tempData[field].concat({}),
    });
  };
  const createNewUdyam = () => {
    setTempData({
      nics: [],
      checks: [],
      bank_detail: {},
      employment_detail: {
        male: 0,
        female: 0,
        other: 0,
      },
      units: [],
    });
    setModal(true);
  };

  const parse = () => {
    navigator.clipboard.readText().then((res) => {
      parseInit(res);
    });
  };
  const parseInit = async (html_init) => {
    const $ = cheerio.load(String(html_init));

    let investments = [];
    let units = [];
    let nics = [];
    let investment_table = $("#main")
      .contents()[1]
      .children[1].children[3].children[1].children[1].children[3].children[1].children[1].children[1].children[1].children[1].children[1].children[0].children[1].children[23].children[1].children.filter(
        (e) => e.type === "tag"
      );

    investment_table = investment_table.splice(1, investment_table.length - 1);
    for (const index of investment_table) {
      const data = index.children.filter((e) => e.type === "tag");

      investments.push({
        financial_year: data[1].children[0].data.trim(),
        enterprise_type: data[2].children[0].data.trim(),
        wdv: data[3].children[0].data.trim(),
        exclusion_of_cost: data[4].children[0].data.trim(),
        net_investment: data[5].children[0].data.trim(),
        total_turnover: data[6].children[0].data.trim(),
        export_turnover: data[7].children[0].data.trim(),
        net_turnover: data[8].children[0].data.trim(),
        is_itr_filled: data[9].children[0].data.trim() === "No" ? false : true,
        itr_type: data[10].children[0].data.trim(),
      });
    }

    // units

    let units_table =
      $("#main").contents()[1].children[1].children[3].children[1].children[1]
        .children[3].children[1].children[1].children[1].children[1].children[1]
        .children[1].children[0].children[1].children[29].children[1]
        .children[0].children[1].children[1].children[1]?.children[1]?.children;

    if (units_table) {
      units_table = units_table.filter((e) => e.type === "tag");
      units_table = units_table.splice(1, units_table.length - 1);

      for (const index of units_table) {
        const main_data = index.children.filter((e) => e.type === "tag");

        units.push({
          name: main_data[1].children[0].data.trim(),
          flat: main_data[2].children[0].data.trim(),
          building: main_data[3].children[0].data.trim(),
          village: main_data[4].children[0].data.trim(),
          block: main_data[5].children[0].data.trim(),
          road: main_data[6].children[0].data.trim(),
          city: main_data[7].children[0].data.trim(),
          pin: main_data[8].children[0].data.trim(),
          state: main_data[9].children[0].data.trim(),
          district: main_data[10].children[0].data.trim(),
        });
      }
    }

    // nics =
    let nics_table = $("#main")
      .contents()[1]
      .children[1].children[3].children[1].children[1].children[3].children[1].children[1].children[1].children[1].children[1].children[1].children[0].children[1].children[41]?.children[1]?.children.filter(
        (e) => e.type === "tag"
      );

    if (nics_table) {
      nics_table = nics_table.splice(1, nics_table.length - 1);

      for (const index in nics_table) {
        const value = nics_table[index].children.filter(
          (e) => e.type === "tag"
        )[3].children[0].data;
        const activity = nics_table[index].children
          .filter((e) => e.type === "tag")[4]
          .children[0].data.trim();
        console.log(nics_table[index].children.filter((e) => e.type === "tag"));
        nics.push({ nic_id: value.split("-")[0].trim(), activity });
      }
    }

    let keyValuePairing = {
      udyam_id: "ctl00_ContentPlaceHolder1_lbludyamregNo",
      major_activity: "ctl00_ContentPlaceHolder1_lblServices",
      type_of_organisation: "ctl00_ContentPlaceHolder1_orgtype",
      name_of_enterprise: "ctl00_ContentPlaceHolder1_lblEnterpriseName",
      type_of_enterprise: "ctl00_ContentPlaceHolder1_lbltype",
      owner_name: "ctl00_ContentPlaceHolder1_lblowner",
      pan: "ctl00_ContentPlaceHolder1_lblpan",
      mobile: "ctl00_ContentPlaceHolder1_lblmainmobile",
      email: "ctl00_ContentPlaceHolder1_lblmainemail",
      social_category: "ctl00_ContentPlaceHolder1_lblsocialcat",
      gender: "ctl00_ContentPlaceHolder1_lblgender",
      divyag: "ctl00_ContentPlaceHolder1_lblph",
      date_of_incorporation: "ctl00_ContentPlaceHolder1_lbldateofincorporation",
      date_of_commencement: "ctl00_ContentPlaceHolder1_lbldateofcommencement",
      bank_name: "ctl00_ContentPlaceHolder1_lblbankname",
      ifsc_code: "ctl00_ContentPlaceHolder1_lblifsc",
      account_no: "ctl00_ContentPlaceHolder1_lblaccount",
      male: "ctl00_ContentPlaceHolder1_lblmale",
      female: "ctl00_ContentPlaceHolder1_lblfemale",
      other: "ctl00_ContentPlaceHolder1_lblother",
      gstin: "ctl00_ContentPlaceHolder1_lblgstn",
      official_address_flat: "ctl00_ContentPlaceHolder1_lblflats",
      official_address_name: "ctl00_ContentPlaceHolder1_lblBuilding",
      official_address_village: "ctl00_ContentPlaceHolder1_lblVillage",
      official_address_block: "ctl00_ContentPlaceHolder1_lblBlock",
      official_address_road: "ctl00_ContentPlaceHolder1_lblRoad",
      official_address_city: "ctl00_ContentPlaceHolder1_lblCity",
      official_address_state: "ctl00_ContentPlaceHolder1_lblState",
      official_address_district: "ctl00_ContentPlaceHolder1_lblDistrict",
      official_address_pin: "ctl00_ContentPlaceHolder1_lblPin",
      official_address_mobile: "ctl00_ContentPlaceHolder1_lblMobile",
      official_address_email: "ctl00_ContentPlaceHolder1_lblEmail",
      ncs_portal: "ctl00_ContentPlaceHolder1_lblNCS",
      treds_portal: "ctl00_ContentPlaceHolder1_lbltreds",
      gem_portal: "ctl00_ContentPlaceHolder1_lblgem",
      dic: "ctl00_ContentPlaceHolder1_lblgmdic",
      msme_di: "ctl00_ContentPlaceHolder1_lblMSMEDI",
      date_of_registration: "ctl00_ContentPlaceHolder1_lblACKNOWLEDGEMENT",
    };

    const form = {};
    await Object.keys(keyValuePairing).forEach(async (element) => {
      form[element] = $("#" + keyValuePairing[element]).text();
    });
    form.units = units;
    form.nics = nics;
    form.investments = investments;

    units = [];
    nics = [];
    investments = [];

    let final_form;
    final_form = {
      udyam_id: form.udyam_id,
      date_of_commencement: form.date_of_commencement
        ? `${form.date_of_commencement.split("/")[2]}-${form.date_of_commencement.split("/")[1]}-${form.date_of_commencement.split("/")[0]}`
        : null,
      date_of_incorporation: form.date_of_incorporation
        ? `${form.date_of_incorporation.split("/")[2]}-${form.date_of_incorporation.split("/")[1]}-${form.date_of_incorporation.split("/")[0]}`
        : null,
      date_of_registration: form.date_of_registration
        ? `${form.date_of_registration.split("/")[2]}-${form.date_of_registration.split("/")[1]}-${form.date_of_registration.split("/")[0]}`
        : null,
      dic: form.dic,
      msme_di: form.msme_di,
      divyag: form.divyag,
      email: form.email,
      name_of_enterprise: form.name_of_enterprise,
      pan: form.pan,
      gstin: form.gstin,
      mobile: form.mobile,
      social_category: form.social_category,
      gender: form.gender,
      gem_portal: form.gem_portal == "Yes" ? true : false,
      treds_portal: form.treds_portal == "Yes" ? true : false,
      ncs_portal: form.ncs_portal == "Yes" ? true : false,
      employment_detail: {
        male: form.male,
        female: form.female,
        other: form.other,
      },
      official_address: {
        flat: form.official_address_flat,
        name: form.official_address_name,
        village: form.official_address_village,
        block: form.official_address_block,
        road: form.official_address_road,
        city: form.official_address_city,
        state: form.official_address_state,
        district: form.official_address_district,
        pin: form.official_address_pin,
        mobile: form.official_address_mobile,
        email: form.official_address_email,
      },
      investments: form.investments,
      units: form.units,
      bank_detail: {
        name: form.bank_name,
        ifsc: form.ifsc_code,
        account_no: form.account_no,
      },
      type_of_enterprise: form.type_of_enterprise,
      type_of_organisation: form.type_of_organisation,
      owner_name: form.owner_name,
      major_activity: form.major_activity,
      nics: form.nics,
    };
    console.log(final_form);
    api
      .post("/api/entity", final_form)
      .then((res) => {
        getData(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
      });
  };

  useEffect(() => {
    getData(1);
  }, []);

  return (
    <Authenticated>
      {clipboardCopied && (
        <Alert
          icon={<CheckIcon fontSize="inherit" />}
          variant="filled"
          className="flex justify-center items-center text-center absolute bottom-4  right-1/2 translate-x-1/2 z-50 "
          severity="info"
        >
          <div className="text-center w-full text-white">
            {clipboardCopied} copied
          </div>
        </Alert>
      )}
      <div className="px-4 sm:px-6 lg:px-8 p-3 overflow-hidden relative">
        <Dialog
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className=" sm:flex sm:flex-row items-center justify-center w-full">
              <div className="text-lg font-semibold">
                Udyam Registration Number :
              </div>
              <div className="text-lg font-light ml-3">{tempData.udyam_id}</div>
            </div>
          </DialogTitle>
          <div className="px-8 xl:px-16 ">
            <div className="mt-3 text-center sm:mt-5">
              <div className="grid grid-cols-1 md:grid-cols-2 mt-10">
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">
                    Type of Enterprise :
                  </div>
                  <div className="font-light ml-3">
                    {tempData.type_of_enterprise}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">
                    Type of Organisation :{" "}
                  </div>
                  <div className="font-light ml-3">
                    {tempData.type_of_organisation}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">Owner Name : </div>
                  <div className="font-light ml-3">{tempData.owner_name}</div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">
                    Do you have GSTIN :{" "}
                  </div>
                  <div className="font-light ml-3">{tempData.gstin}</div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">Email Id : </div>
                  <div className="font-light ml-3">{tempData.email}</div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">Gender : </div>
                  <div className="font-light ml-3">{tempData.gender}</div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">
                    Date of Incorporation :{" "}
                  </div>
                  <div className="font-light ml-3">
                    {tempData.date_of_incorporation}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">Major Activity : </div>
                  <div className="font-light ml-3">
                    {tempData.major_activity}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">
                    Name of Enterprise :{" "}
                  </div>
                  <div className="font-light ml-3">
                    {tempData.name_of_enterprise}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">PAN : </div>
                  <div className="font-light ml-3">{tempData.pan}</div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">Mobile Number : </div>
                  <div className="font-light ml-3">{tempData.mobile}</div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">Social Category : </div>
                  <div className="font-light ml-3">
                    {tempData.social_category}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">
                    Specially Abled(DIVYANG) :
                  </div>
                  <div className="font-light ml-3">
                    {tempData.specially_abled}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between grid-cols-2 mt-2 px-3">
                  <div className="font-bold text-left">
                    Date of Commencement of Product/Business :
                  </div>
                  <div className="font-light">
                    {tempData.date_of_commencement
                      ? formatDate(
                          new Date(tempData.date_of_commencement),
                          "dd-MM-yyyy"
                        )
                      : null}
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <div className=" my-10">
                  <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12"></div>
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Bank Details
                      </h2>
                      <div className="grid grid-cols-1 md:grid-cols-3 mt-10">
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">Bank Name :</div>
                          <div className="font-light ml-3">
                            {tempData.bank_detail?.name}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">IFS Code : </div>
                          <div className="font-light ml-3">
                            {tempData.bank_detail?.ifsc}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">
                            Bank Account Number :{" "}
                          </div>
                          <div className="font-light ml-3">
                            {tempData?.bank_detail?.account_no}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Employment Details
                      </h2>
                      <div className="grid grid-cols-1 md:grid-cols-4 mt-10">
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">Male :</div>
                          <div className="font-light ml-3">
                            {tempData?.employment_detail?.male}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">Female : </div>
                          <div className="font-light ml-3">
                            {tempData?.employment_detail?.female}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">Others : </div>
                          <div className="font-light ml-3">
                            {tempData?.employment_detail?.other}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">Total : </div>
                          <div className="font-light ml-3">
                            {tempData?.employment_detail?.male +
                              tempData?.employment_detail?.female +
                              tempData?.employment_detail?.other}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Investment in Plant and Machinery OR Equipment (in Rs.)
                      </h2>
                      {tempData.investments?.length &&
                        tempData.investments.map((investment, index) => {
                          return (
                            <div
                              className="grid grid-cols-1 md:grid-cols-2 mt-10 pl-10 relative"
                              key={investment.id}
                            >
                              <div className="absolute font-bold mt-2">
                                {index + 1}
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Financial Year :
                                </div>
                                <div className="font-light ml-3">
                                  {investment.financial_year}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Enterprise Type :
                                </div>
                                <div className="font-light ml-3">
                                  {investment.enterprise_type}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Written Down Value (WDV) :
                                </div>
                                <div className="font-light ml-3">
                                  {investment.wdv}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Exclusion of cost of Pollution Control,
                                  Research & Development and Industrial Safety
                                  Devices :
                                </div>
                                <div className="font-light ml-3">
                                  {investment.exclusion_of_cost}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Net Investment in Plant and Machinery OR
                                  Equipment[(A)-(B)] :{" "}
                                </div>
                                <div className="font-light ml-3">
                                  {investment.net_investment}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Total Turnover (A) :
                                </div>
                                <div className="font-light ml-3">
                                  {investment.total_turnover}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Export Turnover (B) :
                                </div>
                                <div className="font-light ml-3">
                                  {investment.export_turnover}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Net Turnover [(A)-(B)] :
                                </div>
                                <div className="font-light ml-3">
                                  {investment.net_turnover}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Is ITR Filled? :
                                </div>
                                <div className="font-light ml-3">
                                  {investment.is_itr_filled}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  ITR Type :{" "}
                                </div>
                                <div className="font-light ml-3">
                                  {investment.itr_type}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Unit(s) Details
                      </h2>
                      {tempData.units &&
                        tempData.units.map((unit, index) => {
                          return (
                            <div
                              key={unit.id}
                              className="grid grid-cols-1 md:grid-cols-2 mt-10 relative pl-10"
                            >
                              <div className="absolute font-bold mt-2">
                                {index + 1}
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Unit Name :
                                </div>
                                <div className="font-light ml-3">
                                  Digital Corner
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Flat :
                                </div>
                                <div className="font-light ml-3">43</div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Building :{" "}
                                </div>
                                <div className="font-light ml-3">
                                  Near Mrb Serkawn
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Village/Town :
                                </div>
                                <div className="font-light ml-3">Serkawn</div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Block :{" "}
                                </div>
                                <div className="font-light ml-3">
                                  Serkawn, lunglei
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Road :{" "}
                                </div>
                                <div className="font-light ml-3">
                                  Near Mrb Serkawn
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  City :
                                </div>
                                <div className="font-light ml-3">Lunglei</div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">Pin :</div>
                                <div className="font-light ml-3">796691</div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  State :
                                </div>
                                <div className="font-light ml-3">MIZORAM</div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  District :{" "}
                                </div>
                                <div className="font-light ml-3">LUNGLEI</div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                      Official address of Enterprise
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 mt-10">
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">
                          Flat/Door/Block No. :
                        </div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.flat}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">
                          Village/Town :
                        </div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.village}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">
                          Road/Street/Lane :{" "}
                        </div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.road}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">State :</div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.state}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">Mobile : </div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.mobile}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">
                          Name of Premises/ Building :{" "}
                        </div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.name}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">Block :</div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.block}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">City :</div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.city}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">District :</div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.district}
                          {" - "}
                          {tempData?.official_address?.pin}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">Email : </div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.email}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                      National Industry Classification Code(S)
                    </h2>
                    {tempData.nics &&
                      tempData.nics.map((nic, index) => (
                        <div
                          className="grid grid-cols-1 md:grid-cols-2 mt-10"
                          key={nic.id}
                        >
                          <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                            <div className="font-bold text-left">
                              Nic 2 Digit :
                            </div>
                            <div className="font-light ml-3">
                              {nic["2_digit"]}
                            </div>
                          </div>
                          <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                            <div className="font-bold text-left">
                              Nic 2 Digit Activity :
                            </div>
                            <div className="font-light ml-3">
                              {nic["2_digit_activity"]}
                            </div>
                          </div>
                          <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                            <div className="font-bold text-left">
                              Nic 4 Digit :
                            </div>
                            <div className="font-light ml-3">
                              {nic["4_digit"]}
                            </div>
                          </div>
                          <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                            <div className="font-bold text-left">
                              Nic 4 Digit Activity :
                            </div>
                            <div className="font-light ml-3">
                              {nic["4_digit_activity"]}
                            </div>
                          </div>
                          <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                            <div className="font-bold text-left">
                              Nic 5 Digit :
                            </div>
                            <div className="font-light ml-3">
                              {nic["5_digit"]}
                            </div>
                          </div>
                          <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                            <div className="font-bold text-left">
                              Nic 5 Digit Activity :
                            </div>
                            <div className="font-light ml-3">
                              {nic["5_digit_activity"]}
                            </div>
                          </div>
                          <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                            <div className="font-bold text-left">
                              Activity :
                            </div>
                            <div className="font-light ml-3">
                              {nic["activity"]}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="">
                    <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                      <div className="font-bold text-left">
                        Are you interested to get registered on Government
                        e-Market (GeM) Portal :{" "}
                      </div>
                      <div className="font-light ml-3">
                        {tempData.gem_portal ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                      <div className="font-bold text-left">
                        Are you interested to get registered on TReDS
                        Portals(one or more) :
                      </div>
                      <div className="font-light ml-3">
                        {tempData.treds_portal ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                      <div className="font-bold text-left">
                        Are you interested to get registered on National Career
                        Service(NCS) Portal :
                      </div>
                      <div className="font-light ml-3">
                        {tempData.ncs_portal ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                      <div className="font-bold text-left">DIC :</div>
                      <div className="font-light ml-3">{tempData.dic}</div>
                    </div>
                    <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                      <div className="font-bold text-left">MSME-DI :</div>
                      <div className="font-light ml-3">{tempData.msme_di}</div>
                    </div>
                    <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                      <div className="font-bold text-left">
                        Date of Udyam Registration :{" "}
                      </div>
                      <div className="font-light ml-3">
                        {tempData.date_of_registration
                          ? formatDate(
                              new Date(tempData.date_of_registration),
                              "dd-MM-yyyy"
                            )
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                      Helpline Remarks :
                    </h2>
                    <div className="text-sm font-light text-justify">
                      {tempData.checks &&
                        tempData.checks.map((check) => (
                          <div className="mt-5">
                            <div className=" text-sm text-black text-justify">
                              {check.remarks}
                            </div>
                            <div className="text-xs font-extralight text-right text-gray-500">
                              - {check.user.name}
                            </div>
                            <div className=" justify-center items-center w-full">
                              <div className=" mx-5 bg-gray-400 h-0.5 mt-2 " />
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center w-full mb-5">
            <Button variant="contained" onClick={handleClose} autoFocus>
              Close
            </Button>
          </div>
        </Dialog>
        <Dialog
          maxWidth="lg"
          open={modal}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className=" sm:flex sm:flex-row items-center justify-center w-full">
              {tempData?.id ? (
                <div>
                  <div className="text-lg font-semibold">
                    Udyam Registration Number :
                  </div>
                  <div className="text-lg font-light ml-3">
                    {tempData.udyam_id}
                  </div>
                </div>
              ) : (
                <div className="text-lg font-semibold">Create New Udyam</div>
              )}
            </div>
          </DialogTitle>
          <form onSubmit={submit} className="p-8 xl:p-15">
            <div className="">
              {tempData?.id && (
                <div className="text-center font-bold">Helpline Center</div>
              )}
              <div className="my-5">
                {tempData?.id && (
                  <div>
                    <TextField
                      label="Remarks"
                      className="w-full"
                      size="small"
                      variant="outlined"
                      multiline
                      onChange={(e) =>
                        setTempData({ ...tempData, remarks: e.target.value })
                      }
                    />
                    <FormControlLabel
                      className="w-full"
                      label="Check this Udyam"
                      control={
                        <Checkbox
                          checked={tempData.is_checked === 1}
                          onChange={(e) => {
                            setTempData({
                              ...tempData,
                              is_checked: e.target.checked ? 1 : 0,
                            });
                          }}
                        />
                      }
                    />
                  </div>
                )}

                <div className="mt-5  grid gap-x-4">
                  <div className="mt-3 text-center sm:mt-5">
                    <div className=" sm:flex sm:flex-row items-center justify-center w-full">
                      <div className="text-lg font-light ">
                        {tempData?.submitEdit ? (
                          <div className="w-full flex gap-x-3 ">
                            <TextField
                              label="Udyam Registration Number"
                              className="xl:w-96"
                              size="small"
                              required
                              variant="outlined"
                              value={tempData.udyam_id}
                              onChange={(e) =>
                                setTempData({
                                  ...tempData,
                                  udyam_id: e.target.value,
                                })
                              }
                            />
                            {tempData?.id && (
                              <Button
                                variant="contained"
                                onClick={() => {
                                  setTempData({
                                    ...tempData,
                                    submitEdit: !tempData.submitEdit,
                                  });
                                }}
                                color="error"
                              >
                                Cancel
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div className="w-full bg-red-300">
                            <Button
                              className="w-full"
                              onClick={() => {
                                setTempData({
                                  ...tempData,
                                  submitEdit: !tempData.submitEdit,
                                });
                              }}
                              variant="contained"
                            >
                              Edit Data
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                    {tempData?.submitEdit ? (
                      <div className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-3">
                        <div className="w-full">
                          <TextField
                            label="Type of Enterprise"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setTempData({
                                ...tempData,
                                type_of_enterprise: e.target.value,
                              })
                            }
                            value={tempData.type_of_enterprise}
                          />
                        </div>
                        <div className="">
                          <TextField
                            label="Type of Organisation"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setTempData({
                                ...tempData,
                                type_of_organisation: e.target.value,
                              })
                            }
                            value={tempData.type_of_organisation}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Owner Name"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setTempData({
                                ...tempData,
                                owner_name: e.target.value,
                              })
                            }
                            value={tempData.owner_name}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Do you have GSTin"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setTempData({
                                ...tempData,
                                gstin: e.target.value,
                              })
                            }
                            value={tempData.gstin}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Email"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setTempData({
                                ...tempData,
                                email: e.target.value,
                              })
                            }
                            value={tempData.email}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Gender"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setTempData({
                                ...tempData,
                                gender: e.target.value,
                              })
                            }
                            value={tempData.gender}
                          />
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={
                              tempData.date_of_incorporation
                                ? new dayjs(tempData.date_of_incorporation)
                                : null
                            }
                            format="DD-MM-YYYY"
                            slotProps={{ textField: { size: "small" } }}
                            label="Date of Incorporation"
                            onChange={(newValue) =>
                              setTempData({
                                ...tempData,
                                date_of_incorporation: format(
                                  new Date(newValue.$d),
                                  "yyyy-MM-dd"
                                ),
                              })
                            }
                            className=""
                          />
                        </LocalizationProvider>
                        <div className="w-full">
                          <TextField
                            label="Major Activity"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setTempData({
                                ...tempData,
                                major_activity: e.target.value,
                              })
                            }
                            value={tempData.major_activity}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Name of Enterprise"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setTempData({
                                ...tempData,
                                name_of_enterprise: e.target.value,
                              })
                            }
                            value={tempData.name_of_enterprise}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="PAN"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setTempData({
                                ...tempData,
                                pan: e.target.value,
                              })
                            }
                            value={tempData.pan}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Mobile"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setTempData({
                                ...tempData,
                                mobile: e.target.value,
                              })
                            }
                            value={tempData.mobile}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Social Category"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setTempData({
                                ...tempData,
                                social_category: e.target.value,
                              })
                            }
                            value={tempData.social_category}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Specially Disabled/DIVYANG"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setTempData({
                                ...tempData,
                                specially_disabled: e.target.value,
                              })
                            }
                            value={tempData.specially_disabled}
                          />
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={
                              tempData.date_of_incorporation
                                ? new dayjs(tempData.date_of_commencement)
                                : null
                            }
                            format={
                              tempData?.date_of_commencement ? "DD-MM-YYYY" : ""
                            }
                            slotProps={{ textField: { size: "small" } }}
                            label="Date of Commencement of Production/Business"
                            onChange={(newValue) =>
                              setTempData({
                                ...tempData,
                                date_of_commencement: format(
                                  new Date(newValue.$d),
                                  "yyyy-MM-dd"
                                ),
                              })
                            }
                            className=""
                          />
                        </LocalizationProvider>
                      </div>
                    ) : (
                      ""
                    )}
                    {tempData.submitEdit ? (
                      <div className="mt-2">
                        <div className=" my-10">
                          <div className="space-y-12">
                            <div className="border-b border-gray-900/10 pb-12"></div>
                            {tempData.bank_detail && (
                              <div className="border-b border-gray-900/10 pb-12">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                  Bank Details
                                </h2>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-10">
                                  <div className="w-full">
                                    <TextField
                                      label="Name"
                                      className="w-full"
                                      size="small"
                                      variant="outlined"
                                      onChange={(e) =>
                                        setTempData({
                                          ...tempData,
                                          bank_detail: {
                                            ...tempData.bank_detail,
                                            name: e.target.value,
                                          },
                                        })
                                      }
                                      value={tempData.bank_detail?.name}
                                    />
                                  </div>
                                  <div className="">
                                    <div className="w-full">
                                      <TextField
                                        label="IFSC"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) =>
                                          setTempData({
                                            ...tempData,
                                            bank_detail: {
                                              ...tempData.bank_detail,
                                              ifsc: e.target.value,
                                            },
                                          })
                                        }
                                        value={tempData.bank_detail?.ifsc}
                                      />
                                    </div>
                                  </div>
                                  <div className="">
                                    <div className="w-full">
                                      <TextField
                                        label="Account No"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) =>
                                          setTempData({
                                            ...tempData,
                                            bank_detail: {
                                              ...tempData.bank_detail,
                                              account_no: e.target.value,
                                            },
                                          })
                                        }
                                        value={tempData.bank_detail?.account_no}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {tempData.employment_detail && (
                              <div className="border-b border-gray-900/10 pb-12">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                  Employment Details
                                </h2>
                                <div className="grid grid-cols-1 md:grid-cols-4 mt-10 gap-3">
                                  <div className="w-full">
                                    <TextField
                                      label="Male"
                                      className="w-full"
                                      size="small"
                                      variant="outlined"
                                      onChange={(e) =>
                                        setTempData({
                                          ...tempData,
                                          employment_detail: {
                                            ...tempData.employment_detail,
                                            male: e.target.value,
                                          },
                                        })
                                      }
                                      value={tempData.employment_detail?.male}
                                    />
                                  </div>
                                  <div className="w-full">
                                    <TextField
                                      label="Female"
                                      className="w-full"
                                      size="small"
                                      variant="outlined"
                                      onChange={(e) =>
                                        setTempData({
                                          ...tempData,
                                          employment_detail: {
                                            ...tempData.employment_detail,
                                            female: e.target.value,
                                          },
                                        })
                                      }
                                      value={tempData.employment_detail?.female}
                                    />
                                  </div>
                                  <div className="w-full">
                                    <TextField
                                      label="Others"
                                      className="w-full"
                                      size="small"
                                      variant="outlined"
                                      onChange={(e) =>
                                        setTempData({
                                          ...tempData,
                                          employment_detail: {
                                            ...tempData.employment_detail,
                                            other: e.target.value,
                                          },
                                        })
                                      }
                                      value={tempData.employment_detail?.other}
                                    />
                                  </div>
                                  <div className="w-full">
                                    <TextField
                                      label="Total"
                                      className="w-full"
                                      size="small"
                                      variant="outlined"
                                      disabled
                                      value={
                                        Number.parseInt(
                                          tempData.employment_detail?.male
                                        ) +
                                        Number.parseInt(
                                          tempData.employment_detail?.female
                                        ) +
                                        Number.parseInt(
                                          tempData.employment_detail?.other
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="border-b border-gray-900/10 pb-12">
                              <h2 className="text-base font-semibold leading-7 text-gray-900">
                                Investment in Plant and Machinery OR Equipment
                                (in Rs.)
                                <Button
                                  onClick={() => handleAddField("investments")}
                                >
                                  <PlusCircleIcon className="h-7 w-7" />
                                </Button>
                              </h2>
                              {tempData?.investments?.map(
                                (investment, index) => (
                                  <div
                                    className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-10 pl-10 relative"
                                    key={index}
                                  >
                                    <div className="absolute top-0 font-bold text-xl">
                                      {index + 1}
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        label="Financial Year"
                                        className="w-full"
                                        size="small"
                                        required
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...tempData.investments,
                                          ];
                                          newInvestments[index].financial_year =
                                            e.target.value;
                                          setTempData({
                                            ...tempData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.financial_year}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        label="Enterprise Type"
                                        className="w-full"
                                        required
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...tempData.investments,
                                          ];
                                          newInvestments[
                                            index
                                          ].enterprise_type = e.target.value;
                                          setTempData({
                                            ...tempData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.enterprise_type}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Written Down Value"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...tempData.investments,
                                          ];
                                          newInvestments[index].wdv =
                                            e.target.value;
                                          setTempData({
                                            ...tempData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.wdv}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label=" Exclusion of cost"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...tempData.investments,
                                          ];
                                          newInvestments[
                                            index
                                          ].exclusion_of_cost = e.target.value;
                                          setTempData({
                                            ...tempData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.exclusion_of_cost}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Net Investment in Plant and Machinery/Equipment"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...tempData.investments,
                                          ];
                                          newInvestments[index].net_investment =
                                            e.target.value;
                                          setTempData({
                                            ...tempData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.net_investment}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Total Turnover"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...tempData.investments,
                                          ];
                                          newInvestments[index].total_turnover =
                                            e.target.value;
                                          setTempData({
                                            ...tempData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.total_turnover}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Export Turnover"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...tempData.investments,
                                          ];
                                          newInvestments[
                                            index
                                          ].export_turnover = e.target.value;
                                          setTempData({
                                            ...tempData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.export_turnover}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Net Turnover"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...tempData.investments,
                                          ];
                                          newInvestments[index].net_turnover =
                                            e.target.value;
                                          setTempData({
                                            ...tempData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.net_turnover}
                                      />
                                    </div>
                                    <FormControlLabel
                                      label="Is ITR Filled?"
                                      control={
                                        <Checkbox
                                          checked={
                                            investment.is_itr_filled === "Yes"
                                          }
                                          onChange={() => {
                                            const newInvestments = [
                                              ...tempData.investments,
                                            ];
                                            newInvestments[
                                              index
                                            ].is_itr_filled =
                                              investment.is_itr_filled === "Yes"
                                                ? "No"
                                                : "Yes";
                                            setTempData({
                                              ...tempData,
                                              investments: newInvestments,
                                            });
                                          }}
                                        />
                                      }
                                    />
                                    <div className="w-full">
                                      <TextField
                                        label="ITR Type"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...tempData.investments,
                                          ];
                                          newInvestments[index].itr_type =
                                            e.target.value;
                                          setTempData({
                                            ...tempData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.itr_type}
                                      />
                                    </div>
                                    <div className="w-full md:col-start-2">
                                      <Button
                                        onClick={() =>
                                          handleRemoveField(
                                            "investments",
                                            index
                                          )
                                        }
                                        variant="contained"
                                        color="error"
                                      >
                                        Remove
                                      </Button>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                            <div className="border-b border-gray-900/10 pb-12">
                              <h2 className="text-base font-semibold leading-7 text-gray-900">
                                Unit(s) Details
                                <Button onClick={() => handleAddField("units")}>
                                  <PlusCircleIcon className="h-7 w-7" />
                                </Button>
                              </h2>
                              {tempData?.units?.map((unit, index) => {
                                return (
                                  <div
                                    key={unit.id}
                                    className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-3 relative pl-10"
                                  >
                                    <div className="absolute font-bold text-xl">
                                      {index + 1}
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Unit Name"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...tempData.units];
                                          newUnits[index].name = e.target.value;
                                          setTempData({
                                            ...tempData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.name}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Flat"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...tempData.units];
                                          newUnits[index].flat = e.target.value;
                                          setTempData({
                                            ...tempData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.flat}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Building"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...tempData.units];
                                          newUnits[index].building =
                                            e.target.value;
                                          setTempData({
                                            ...tempData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.building}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Village/Town"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...tempData.units];
                                          newUnits[index].village =
                                            e.target.value;
                                          setTempData({
                                            ...tempData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.village}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Block"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...tempData.units];
                                          newUnits[index].block =
                                            e.target.value;
                                          setTempData({
                                            ...tempData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.block}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Road"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...tempData.units];
                                          newUnits[index].road = e.target.value;
                                          setTempData({
                                            ...tempData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.road}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="City"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...tempData.units];
                                          newUnits[index].city = e.target.value;
                                          setTempData({
                                            ...tempData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.city}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Pin"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...tempData.units];
                                          newUnits[index].pin = e.target.value;
                                          setTempData({
                                            ...tempData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.pin}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="State"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...tempData.units];
                                          newUnits[index].state =
                                            e.target.value;
                                          setTempData({
                                            ...tempData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.state}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="District"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...tempData.units];
                                          newUnits[index].district =
                                            e.target.value;
                                          setTempData({
                                            ...tempData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.district}
                                      />
                                    </div>
                                    <div className="w-full md:col-start-2">
                                      <Button
                                        onClick={() =>
                                          handleRemoveField("units", index)
                                        }
                                        variant="contained"
                                        color="error"
                                      >
                                        Remove
                                      </Button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                              Official address of Enterprise
                            </h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-3">
                              <div className="w-full">
                                <TextField
                                  label="Flat/Door/Block No"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setTempData({
                                      ...tempData,
                                      official_address: {
                                        ...tempData.official_address,
                                        flat: e.target.value,
                                      },
                                    });
                                  }}
                                  value={tempData?.official_address?.flat}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Village/Town"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setTempData({
                                      ...tempData,
                                      official_address: {
                                        ...tempData.official_address,
                                        village: e.target.value,
                                      },
                                    });
                                  }}
                                  value={tempData?.official_address?.village}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Road/Street/Lane"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setTempData({
                                      ...tempData,
                                      official_address: {
                                        ...tempData.official_address,
                                        road: e.target.value,
                                      },
                                    });
                                  }}
                                  value={tempData?.official_address?.road}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="State"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setTempData({
                                      ...tempData,
                                      official_address: {
                                        ...tempData.official_address,
                                        state: e.target.value,
                                      },
                                    });
                                  }}
                                  value={tempData?.official_address?.state}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Mobile"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setTempData({
                                      ...tempData,
                                      official_address: {
                                        ...tempData.official_address,
                                        mobile: e.target.value,
                                      },
                                    });
                                  }}
                                  value={tempData?.official_address?.mobile}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Name of Premises/Building"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setTempData({
                                      ...tempData,
                                      official_address: {
                                        ...tempData.official_address,
                                        name: e.target.value,
                                      },
                                    });
                                  }}
                                  value={tempData?.official_address?.name}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Block"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setTempData({
                                      ...tempData,
                                      official_address: {
                                        ...tempData.official_address,
                                        block: e.target.value,
                                      },
                                    });
                                  }}
                                  value={tempData?.official_address?.block}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="City"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setTempData({
                                      ...tempData,
                                      official_address: {
                                        ...tempData.official_address,
                                        city: e.target.value,
                                      },
                                    });
                                  }}
                                  value={tempData?.official_address?.city}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="District"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setTempData({
                                      ...tempData,
                                      official_address: {
                                        ...tempData.official_address,
                                        district: e.target.value,
                                      },
                                    });
                                  }}
                                  value={tempData?.official_address?.district}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Email"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setTempData({
                                      ...tempData,
                                      official_address: {
                                        ...tempData.official_address,
                                        email: e.target.value,
                                      },
                                    });
                                  }}
                                  value={tempData?.official_address?.email}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                              National Industry Classification Code(S)
                              <Button onClick={() => handleAddField("nics")}>
                                <PlusCircleIcon className="h-7 w-7" />
                              </Button>
                            </h2>
                            {tempData?.nics?.map((nic, index) => {
                              return (
                                <div
                                  key={index}
                                  className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-3 pl-10 relative"
                                >
                                  <div className="absolute text-xl font-bold">
                                    {index + 1}
                                  </div>

                                  <div className="w-full">
                                    <TextField
                                      label="Nic 5 Digit"
                                      className="w-full"
                                      size="small"
                                      variant="outlined"
                                      onChange={(e) => {
                                        const newNics = [...tempData.nics];
                                        newNics[index].nic_id = e.target.value;
                                        setTempData({
                                          ...tempData,
                                          nics: newNics,
                                        });
                                      }}
                                      value={nic.nic_id}
                                    />
                                  </div>

                                  <div className="w-full">
                                    <TextField
                                      label="Main Activity"
                                      className="w-full"
                                      size="small"
                                      variant="outlined"
                                      onChange={(e) => {
                                        const newNics = [...tempData.nics];
                                        newNics[index].activity =
                                          e.target.value;
                                        setTempData({
                                          ...tempData,
                                          nics: newNics,
                                        });
                                      }}
                                      value={nic.activity}
                                    />
                                  </div>
                                  <div className="w-full md:col-span-2">
                                    <Button
                                      onClick={() =>
                                        handleRemoveField("nics", index)
                                      }
                                      variant="contained"
                                      color="error"
                                    >
                                      Remove
                                    </Button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="w-full mt-10 grid grid-cols-1">
                            <div>
                              <FormControlLabel
                                className="w-full"
                                label=" Are you interested to get registered on
                                    Government e-Market (GeM) Portal"
                                control={
                                  <Checkbox
                                    checked={tempData.gem_portal === 0}
                                    onChange={(e) => {
                                      setTempData({
                                        ...tempData,
                                        gem_portal: e.target.checked ? 1 : 0,
                                      });
                                    }}
                                  />
                                }
                              />
                            </div>
                            <div>
                              <FormControlLabel
                                className="w-full"
                                label="Are you interested to get registered on TReDS
                                  Portals(one or more) "
                                control={
                                  <Checkbox
                                    checked={tempData.treds_portal === 1}
                                    onChange={(e) => {
                                      setTempData({
                                        ...tempData,
                                        treds_portal: e.target.checked ? 1 : 0,
                                      });
                                    }}
                                  />
                                }
                              />
                            </div>
                            <div>
                              <FormControlLabel
                                className="w-full"
                                label="Are you interested to get registered on NCS
                                  Portals(one or more) "
                                control={
                                  <Checkbox
                                    checked={tempData.ncs_portal === 1}
                                    onChange={(e) => {
                                      setTempData({
                                        ...tempData,
                                        ncs_portal: e.target.checked ? 1 : 0,
                                      });
                                    }}
                                  />
                                }
                              />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-5">
                              <TextField
                                label="DIC"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) =>
                                  setTempData({
                                    ...tempData,
                                    dic: e.target.value,
                                  })
                                }
                                value={tempData.dic}
                              />
                              <TextField
                                label="MSME DI"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) =>
                                  setTempData({
                                    ...tempData,
                                    msme_di: e.target.value,
                                  })
                                }
                                value={tempData.msme_di}
                              />
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  value={
                                    tempData.date_of_registration
                                      ? new dayjs(tempData.date_of_registration)
                                      : null
                                  }
                                  format="DD-MM-YYYY"
                                  slotProps={{
                                    textField: { size: "small" },
                                  }}
                                  label="Udyam Registration Date"
                                  onChange={(newValue) =>
                                    setTempData({
                                      ...tempData,
                                      date_of_registration: format(
                                        new Date(newValue.$d),
                                        "yyyy-MM-dd"
                                      ),
                                    })
                                  }
                                  className=""
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                          <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                              Helpline Remarks :
                            </h2>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      className="w-full mt-5 rounded-md border-gray-300"
                      style={{ borderWidth: "1px" }}
                    >
                      {tempData.checks &&
                        tempData?.checks?.map((check, index) => (
                          <div className="grid md:grid-cols-3" key={index}>
                            <div
                              className=" text-xs p-2 relative"
                              style={{
                                borderRightWidth: "1px",
                                borderBottomWidth:
                                  index !== tempData.checks.length - 1
                                    ? "1px"
                                    : "0px",
                              }}
                            >
                              {check?.user?.name}
                            </div>
                            <div
                              className=" text-xs p-2"
                              style={{
                                borderRightWidth: "1px",
                                borderBottomWidth:
                                  index !== tempData.checks.length - 1
                                    ? "1px"
                                    : "0px",
                              }}
                            >
                              {formatDate(
                                new Date(check?.checked_on),
                                "dd-MM-yyyy"
                              )}
                            </div>
                            <div
                              className=" text-xs p-2"
                              style={{
                                borderRightWidth: "1px",
                                borderBottomWidth:
                                  index !== tempData.checks.length - 1
                                    ? "1px"
                                    : "0px",
                              }}
                            >
                              {check?.remarks}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3 mt-10">
                <Button type="submit" variant="contained">
                  Submit
                </Button>
                <Button
                  className="mt-3 md:mt-0"
                  color="error"
                  onClick={() => setModal(false)}
                  variant="contained"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </Dialog>
        <div className="">
          <div className="grid md:grid-cols-4 grid-cols-1 gap-4">
            <div className="md:mt-0 mt-10">
              <TextField
                label="Udyam No."
                className="w-full"
                size="small"
                value={udyamNo}
                onChange={(e) => setUdyamNo(e.target.value)}
                variant="outlined"
              />
            </div>
            <div className="md:mt-0 mt-10">
              <TextField
                label="Official address"
                value={officialAddress}
                className="w-full"
                size="small"
                variant="outlined"
                onChange={(e) => setOfficialAddress(e.target.value)}
              />
            </div>
            <TextField
              label="Owner Name"
              value={ownerName}
              className="w-full"
              size="small"
              variant="outlined"
              onChange={(e) => setOwnerName(e.target.value)}
            />
            <div className="grid grid-cols-2">
              <FormControlLabel
                className="w-full"
                label="All Udyam"
                control={
                  <Checkbox
                    checked={allUdyam}
                    onChange={(e) => {
                      setAllUdyam(e.target.checked);
                    }}
                  />
                }
              />
              <FormControlLabel
                className="w-full"
                label="No Units"
                control={
                  <Checkbox
                    checked={noUnits}
                    onChange={(e) => {
                      setNoUnits(e.target.checked);
                    }}
                  />
                }
              />
            </div>
          </div>
          <div className="md:mt-10 mt-10 grid grid-cols-1 gap-4 md:grid-cols-6">
            <div className=" w-full md:col-span-1 col-span-2">
              <TextField
                className="w-full"
                select
                size="small"
                id="demo-simple-select"
                value={selectedDistrict}
                label="District"
                onChange={(e) => setselectedDistrict(e.target.value)}
              >
                {district.map((dist) => (
                  <MenuItem key={dist.id} value={dist.name}>
                    {dist.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="md:col-span-1 col-span-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={startDate ? new dayjs(startDate) : null}
                  format="DD-MM-YYYY"
                  slotProps={{ textField: { size: "small" } }}
                  label="From"
                  onChange={(newValue) => {
                    if (newValue.$d != "Invalid Date") {
                      setStartDate(format(new Date(newValue.$d), "yyyy-MM-dd"));
                    }
                  }}
                  className="w-full"
                />
              </LocalizationProvider>
            </div>

            <div className="md:col-span-1 col-span-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={endDate ? new dayjs(endDate) : null}
                  format="DD-MM-YYYY"
                  slotProps={{ textField: { size: "small" } }}
                  label="To"
                  onChange={(newValue) => {
                    console.log(newValue);
                    if (newValue.$d != "Invalid Date") {
                      setEndDate(format(new Date(newValue.$d), "yyyy-MM-dd"));
                    }
                  }}
                  className="w-full"
                />
              </LocalizationProvider>
            </div>
            <div className=" col-span-2 grid grid-cols-3  gap-x-2 mx-3">
              <Button
                onClick={() => getData(1)}
                variant="contained"
                size="small"
              >
                Submit
              </Button>
              <Button
                onClick={() => resetSearchQuery()}
                variant="contained"
                color="error"
                size="small"
              >
                Cancel
              </Button>
              <Button
                type="button"
                onClick={() => parse()}
                variant="contained"
                color="primary"
                size="small"
              >
                Parse
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            {isBusy ? (
              <div className="flex justify-center">
                <InfinitySpin
                  visible={true}
                  width="200"
                  color="#4fa94d"
                  ariaLabel="infinity-spin-loading"
                />
              </div>
            ) : (
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
                <div className="max-w-full overflow-hidden">
                  <Paper>
                    <TableContainer>
                      <Table
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                      >
                        {/* replicate in all tables */}
                        {!isMobile && (
                          <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                          />
                        )}
                        <TableBody>
                          {entities?.data.map((row, index) => {
                            const isItemSelected = selected.includes(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, row)}
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                                sx={{
                                  cursor: "pointer",
                                  display: isMobile ? "grid" : "table-row",
                                }}
                                style={{
                                  backgroundColor: isMobile ? "" : "white",
                                  borderWidth: isMobile ? "1px" : "0px",
                                  borderRadius: isMobile ? "3px" : "0px",
                                  marginTop: isMobile ? "3px" : "0px",
                                  padding: isMobile ? "3px" : "0px",
                                  borderColor: isMobile ? "grey" : "white",
                                }}
                              >
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="left"
                                  onClick={(e) =>
                                    copyToClipboard(e, row.udyam_id)
                                  }
                                >
                                  <div>{row.udyam_id}</div>
                                </TableCell>
                                <TableCell align="left">
                                  {row.owner_name}
                                </TableCell>

                                <TableCell align="left">
                                  {row?.mobile}
                                </TableCell>
                                <TableCell align="left">
                                  <Button
                                    variant="contained"
                                    disableElevation
                                    size="small"
                                    autoCapitalize="none"
                                    className="text-white  w-28 h-8 rounded-md"
                                    type="button"
                                    onClick={(event) =>
                                      openCheckModal(event, row)
                                    }
                                  >
                                    Check{" "}
                                    {row.checks.length
                                      ? row.checks?.length
                                      : ""}
                                    <span className="sr-only">{row.name}</span>
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          {entities?.data.length - entities?.per_page > 0 && (
                            <TableRow
                              style={{
                                height:
                                  (dense ? 33 : 53) *
                                  (entities?.data.length - entities?.per_page),
                              }}
                            >
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div className="relative py-3 ">
                      <div className="mt-2 w-full absolute md:left-1/2 -translate-x-1/2 flex justify-center z-30">
                        <Pagination
                          count={Math.ceil(entities.total / entities.per_page)}
                          page={entities.current_page}
                          onChange={(e, number) => getData(number)}
                        />
                      </div>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={entities?.total}
                        rowsPerPage={entities?.per_page}
                        page={entities?.current_page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </Paper>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Authenticated>
  );
}
