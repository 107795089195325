import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  AccountBalance,
  AccountBalanceWallet,
  AccountCircle,
  AccountTree,
  Apartment,
  Assignment,
  Dns,
  ExpandLess,
  ExpandMore,
  Folder,
  Home,
  HomeMax,
  HomeWork,
  Output,
  People,
  QuestionAnswer,
  Settings,
  Sick,
} from "@mui/icons-material";
import {
  Cog8ToothIcon,
  FolderIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { Collapse } from "@mui/material";
import { useLocation, useNavigation } from "react-router-dom";
import { api } from "../axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const drawerWidth = 240;
function ResponsiveDrawer({ children }) {
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [roles, setRoles] = useState();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [open, setOpen] = useState(false);
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const navigateTo = (url) => {
    window.location = url;
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const handleClick = () => {
    setOpen(!open);
  };
  const handleLogOut = () => {
    api.get("/api/test").then((res) => {
      window.location = "/login";
      console.log(res);
      // api.get("/sanctum/csrf-cookie").then((response) => {
      //   console.log("Cookies set");
      // });
    });
  };

  useEffect(() => {
    console.log(user);
    setRoles(user?.roles?.map((e) => e.name)?.[0]);
  }, [user]);
  const drawer = (
    <div>
      <Toolbar>
        <div className="h-16 shrink-0 items-center flex flex-row">
          <img alt="Your Company" src="logo.webp" className="h-12 w-auto" />
          <div className=" pl-3">
            <div className="font-semibold">MEMiz</div>
            <div className="font-light text-sm">Elevate Mizoram</div>
          </div>
        </div>
      </Toolbar>
      <Divider />

      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {["helpline"].includes(roles) && (
          <ListItemButton
            style={{
              backgroundColor:
                location.pathname === "/dashboard" ? "rgb(107, 110, 171)" : "",
              color: location.pathname === "/dashboard" ? "white" : "",
            }}
            onClick={() => navigateTo("/dashboard")}
          >
            <ListItemIcon
              style={{
                color: location.pathname === "/dashboard" ? "white" : "",
              }}
              children={<Home />}
            />
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        )}
        {["helpline","district_lead_resource","verification_staff"].includes(roles) && (
          <ListItemButton
            style={{
              backgroundColor:
                location.pathname === "/alludyam" ? "rgb(107, 110, 171)" : "",
              color: location.pathname === "/alludyam" ? "white" : "",
            }}
            onClick={() => navigateTo("/alludyam")}
          >
            <ListItemIcon children={<HomeWork />} />
            <ListItemText primary="Udyam" />
          </ListItemButton>
        )}

        {["district_lead_resource"].includes(roles) && (
          <ListItemButton onClick={() => navigateTo("/assignment")}>
            <ListItemIcon children={<Assignment />} />
            <ListItemText primary="Assignment" />
          </ListItemButton>
        )}

         {["verification_staff", "district_lead_resource", "m_and_e"].includes(
          roles
        ) && (
          <ListItemButton onClick={() => navigateTo("/verify")}>
            <ListItemIcon children={<AccountTree />} />
            <ListItemText primary="In Process" />
          </ListItemButton>
        )}
         { (
          <ListItemButton onClick={() => navigateTo("/editModel")}>
            <ListItemIcon children={<QuestionAnswer />} />
            <ListItemText primary="Edit Request" />
          </ListItemButton>
        )}
        {[
          "helpline",
          "verification_staff",
          "district_lead_resource",
          "m_and_e",
          "superadmin",
        ].includes(roles) && (
          <ListItemButton
            style={{
              backgroundColor:
                location.pathname === "/approved" ? "rgb(107, 110, 171)" : "",
              color: location.pathname === "/approved" ? "white" : "",
            }}
            onClick={() => navigateTo("/approved")}
          >
            <ListItemIcon children={<AccountBalanceWallet />} />
            <ListItemText primary="Index" />
          </ListItemButton>
        )}

        {(user?.roles?.length===0) ? (
          <ListItemButton
            style={{
              backgroundColor:
                location.pathname === "/myUdyam" ? "rgb(107, 110, 171)" : "",
              color: location.pathname === "/myUdyam" ? "white" : "",
            }}
            onClick={() => navigateTo("/myUdyam")}
          >
            <ListItemIcon children={<Dns />} />
            <ListItemText primary="My Udyam" />
          </ListItemButton>
        ):''}
        {["superadmin", "m_and_e"].includes(roles) && (
          <ListItemButton
            style={{
              backgroundColor:
                location.pathname === "/users" ? "rgb(107, 110, 171)" : "",
              color: location.pathname === "/users" ? "white" : "",
            }}
            onClick={() => navigateTo("/users")}
          >
            <ListItemIcon children={<People />} />
            <ListItemText primary="Users" />
          </ListItemButton>
        )}
        {["helpline", "m_and_e", "superadmin"].includes(roles) && (
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Query" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        )}

        {["helpline", "m_and_e", "superadmin"].includes(roles) && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton onClick={() => navigateTo("/queryType")}>
                <ListItemIcon children={<QuestionAnswer />} />
                <ListItemText primary="Query Type" />
              </ListItemButton>
              <ListItemButton onClick={() => navigateTo("/query")}>
                <ListItemIcon children={<QuestionAnswer />} />
                <ListItemText primary="Queries" />
              </ListItemButton>
            </List>
          </Collapse>
        )}
        <ListItemButton
          style={{
            backgroundColor:
              location.pathname === "/editProfile" ? "rgb(107, 110, 171)" : "",
            color: location.pathname === "/editProfile" ? "white" : "",
          }}
          onClick={() => navigateTo("/editProfile")}
        >
          <ListItemIcon
            style={{
              color: location.pathname === "/editProfile" ? "white" : "",
            }}
            children={<AccountCircle />}
          />
          <ListItemText primary="Profile" />
        </ListItemButton>
        <ListItemButton onClick={handleLogOut}>
          <ListItemIcon>
            <Output />
          </ListItemIcon>
          <ListItemText primary="SignOut" />
        </ListItemButton>
      </List>
      <Divider />
    </div>
  );

  // Remove this const when copying and pasting into your project.
  // const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        elevation={0}
        className="bg-none"
        style={{ backgroundColor: "none !important" }}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar className="bg-white shadow-none">
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          elevation={0}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          pt: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
