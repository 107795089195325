"use client";

import { Fragment, useEffect, useState } from "react";

import Authenticated from "../Layout/Authenticated";
import { api } from "../axios";
import { format, formatDate } from "date-fns";
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { InfinitySpin } from "react-loader-spinner";
import { isMobile } from "react-device-detect";

export default function Dashboard() {
  const [mainData, setMaindata] = useState({});
  const [isBusy, setIsBusy] = useState(false);

  const getMyUdyam = () => {
    api
      .get("api/myudyam")
      .then((res) => {
        const data = res.data;
        data.submitEdit = true;
        setMaindata(data);
      })
      .catch((err) => {
        if (err.status === 401) {
          window.alert("You are not authorized to access this page");
          window.location = "/login";
        }
      })
      .finally(() => {
        setIsBusy(false);
      });
  };

  useEffect(() => {
    getMyUdyam();
  }, []);

  /**
   * Handles the submission of the current form or data.
   */
  const submit = (e) => {
    e.preventDefault();
    mainData._method = "patch";
    mainData.units = mainData.units_with_files_verification;

    mainData.remarks = "User triggered edits";
    api
      .post(`/api/udyam/${mainData.id}`, mainData)
      .then((res) => {
        if (res.data.message === "No changes made") {
          window.alert("No changes made");
          getMyUdyam();
        } else {
          window.alert("Your update request has been processed successfully");
          getMyUdyam();
        }
        console.log(res);
      })
      .catch((err) => {
        window.alert("Some error exists please contact system administrator");
        console.log(err);
      });
  };
  const handleRemoveField = async (field, index) => {
    setMaindata({
      ...mainData,
      [field]: mainData[field].filter((_, i) => i !== index),
    });
  };

  const handleAddField = (field) => {
    setMaindata({
      ...mainData,
      [field]: mainData[field].concat({}),
    });
  };

  return (
    <Authenticated>
      {isBusy ? (
        <div className="w-full min-h-screen flex justify-center items-center">
          <InfinitySpin
            visible={true}
            width="200"
            color="#4fa94d"
            ariaLabel="infinity-spin-loading"
          />
        </div>
      ) : (
        <div className="">
          <form onSubmit={(e) => submit(e)} className="p-8 xl:p-15">
            <div className="">
              <div className="my-5">
                <div className="mt-5  grid gap-x-4">
                  <div className="mt-3 text-center sm:mt-5">
                    <div className="sm:flex sm:flex-row items-center justify-center w-full">
                      <div className="text-lg font-light ">
                        {
                          <div className="w-full">
                            <TextField
                              label="Udyam Registration Number"
                              className="w-full select-none"
                              size="small"
                              disabled
                              required
                              variant="outlined"
                              value={
                                mainData?.udyam_id ? mainData?.udyam_id : ""
                              }
                              onChange={(e) =>
                                window.alert("Editing Udyam ID not allowed")
                              }
                            />
                          </div>
                        }
                      </div>
                    </div>
                    {
                      <div className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-3">
                        <div className="w-full">
                          <TextField
                            label="Type of Enterprise"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setMaindata({
                                ...mainData,
                                type_of_enterprise: e.target.value,
                              })
                            }
                            value={
                              mainData?.type_of_enterprise
                                ? mainData.type_of_enterprise
                                : ""
                            }
                          />
                        </div>
                        <div className="">
                          <TextField
                            label="Type of Organisation"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setMaindata({
                                ...mainData,
                                type_of_organisation: e.target.value,
                              })
                            }
                            value={
                              mainData?.type_of_organisation
                                ? mainData.type_of_organisation
                                : ""
                            }
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Owner Name"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setMaindata({
                                ...mainData,
                                owner_name: e.target.value,
                              })
                            }
                            value={
                              mainData?.owner_name ? mainData.owner_name : ""
                            }
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Do you have GSTin"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setMaindata({
                                ...mainData,
                                gstin: e.target.value,
                              })
                            }
                            value={mainData?.gstin ? mainData.gstin : ""}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Email"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setMaindata({
                                ...mainData,
                                email: e.target.value,
                              })
                            }
                            value={mainData?.email ? mainData.email : ""}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Gender"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setMaindata({
                                ...mainData,
                                gender: e.target.value,
                              })
                            }
                            value={mainData?.gender ? mainData.gender : ""}
                          />
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={
                              mainData.date_of_incorporation
                                ? new dayjs(mainData.date_of_incorporation)
                                : null
                            }
                            format="DD-MM-YYYY"
                            slotProps={{ textField: { size: "small" } }}
                            label="Date of Incorporation"
                            onChange={(newValue) =>
                              setMaindata({
                                ...mainData,
                                date_of_incorporation: format(
                                  new Date(newValue.$d),
                                  "yyyy-MM-dd"
                                ),
                              })
                            }
                            className=""
                          />
                        </LocalizationProvider>
                        <div className="w-full">
                          <TextField
                            label="Major Activity"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setMaindata({
                                ...mainData,
                                major_activity: e.target.value,
                              })
                            }
                            value={
                              mainData?.major_activity
                                ? mainData.major_activity
                                : ""
                            }
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Name of Enterprise"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setMaindata({
                                ...mainData,
                                name_of_enterprise: e.target.value,
                              })
                            }
                            value={
                              mainData?.name_of_enterprise
                                ? mainData.name_of_enterprise
                                : ""
                            }
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="PAN"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setMaindata({
                                ...mainData,
                                pan: e.target.value,
                              })
                            }
                            value={mainData?.pan ? mainData.pan : ""}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Mobile"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setMaindata({
                                ...mainData,
                                mobile: e.target.value,
                              })
                            }
                            value={mainData?.mobile ? mainData.mobile : ""}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Social Category"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setMaindata({
                                ...mainData,
                                social_category: e.target.value,
                              })
                            }
                            value={
                              mainData?.social_category
                                ? mainData.social_category
                                : ""
                            }
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Specially Disabled/DIVYANG"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              setMaindata({
                                ...mainData,
                                specially_disabled: e.target.value,
                              })
                            }
                            value={mainData?.divyag ? mainData.divyag : ""}
                          />
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={
                              mainData.date_of_incorporation
                                ? new dayjs(mainData.date_of_commencement)
                                : null
                            }
                            format={
                              mainData?.date_of_commencement ? "DD-MM-YYYY" : ""
                            }
                            slotProps={{ textField: { size: "small" } }}
                            label="Date of Commencement of Production/Business"
                            onChange={(newValue) =>
                              setMaindata({
                                ...mainData,
                                date_of_commencement: format(
                                  new Date(newValue.$d),
                                  "yyyy-MM-dd"
                                ),
                              })
                            }
                            className=""
                          />
                        </LocalizationProvider>
                      </div>
                    }
                    {
                      <div className="mt-2">
                        <div className=" my-10">
                          <div className="space-y-12">
                            <div className="border-b border-gray-900/10 pb-12"></div>
                            {mainData.bank_detail && (
                              <div className="border-b border-gray-900/10 pb-12">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                  Bank Details
                                </h2>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-10">
                                  <div className="w-full">
                                    <TextField
                                      label="Name"
                                      className="w-full"
                                      size="small"
                                      variant="outlined"
                                      onChange={(e) =>
                                        setMaindata({
                                          ...mainData,
                                          bank_detail: {
                                            ...mainData.bank_detail,
                                            name: e.target.value,
                                          },
                                        })
                                      }
                                      value={mainData.bank_detail?.name}
                                    />
                                  </div>
                                  <div className="">
                                    <div className="w-full">
                                      <TextField
                                        label="IFSC"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) =>
                                          setMaindata({
                                            ...mainData,
                                            bank_detail: {
                                              ...mainData.bank_detail,
                                              ifsc: e.target.value,
                                            },
                                          })
                                        }
                                        value={mainData.bank_detail?.ifsc}
                                      />
                                    </div>
                                  </div>
                                  <div className="">
                                    <div className="w-full">
                                      <TextField
                                        label="Account No"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) =>
                                          setMaindata({
                                            ...mainData,
                                            bank_detail: {
                                              ...mainData.bank_detail,
                                              account_no: e.target.value,
                                            },
                                          })
                                        }
                                        value={mainData.bank_detail?.account_no}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {mainData.employment_detail && (
                              <div className="border-b border-gray-900/10 pb-12">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                  Employment Details
                                </h2>
                                <div className="grid grid-cols-1 md:grid-cols-4 mt-10 gap-3">
                                  <div className="w-full">
                                    <TextField
                                      label="Male"
                                      className="w-full"
                                      size="small"
                                      variant="outlined"
                                      onChange={(e) =>
                                        setMaindata({
                                          ...mainData,
                                          employment_detail: {
                                            ...mainData.employment_detail,
                                            male: e.target.value,
                                          },
                                        })
                                      }
                                      value={mainData.employment_detail?.male}
                                    />
                                  </div>
                                  <div className="w-full">
                                    <TextField
                                      label="Female"
                                      className="w-full"
                                      size="small"
                                      variant="outlined"
                                      onChange={(e) =>
                                        setMaindata({
                                          ...mainData,
                                          employment_detail: {
                                            ...mainData.employment_detail,
                                            female: e.target.value,
                                          },
                                        })
                                      }
                                      value={mainData.employment_detail?.female}
                                    />
                                  </div>
                                  <div className="w-full">
                                    <TextField
                                      label="Others"
                                      className="w-full"
                                      size="small"
                                      variant="outlined"
                                      onChange={(e) =>
                                        setMaindata({
                                          ...mainData,
                                          employment_detail: {
                                            ...mainData.employment_detail,
                                            other: e.target.value,
                                          },
                                        })
                                      }
                                      value={mainData.employment_detail?.other}
                                    />
                                  </div>
                                  <div className="w-full">
                                    <TextField
                                      label="Total"
                                      className="w-full"
                                      size="small"
                                      variant="outlined"
                                      disabled
                                      value={
                                        Number.parseInt(
                                          mainData.employment_detail?.male
                                        ) +
                                        Number.parseInt(
                                          mainData.employment_detail?.female
                                        ) +
                                        Number.parseInt(
                                          mainData.employment_detail?.other
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="border-b border-gray-900/10 pb-12">
                              <h2 className="text-base font-semibold leading-7 text-gray-900">
                                Investment in Plant and Machinery OR Equipment
                                (in Rs.)
                                <Button
                                  onClick={() => handleAddField("investments")}
                                >
                                  <PlusCircleIcon className="h-7 w-7" />
                                </Button>
                              </h2>
                              {mainData?.investments?.map(
                                (investment, index) => (
                                  <div
                                    className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-10 pl-10 relative"
                                    key={index}
                                  >
                                    <div className="absolute top-0 font-bold text-xl">
                                      {index + 1}
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        label="Financial Year"
                                        className="w-full"
                                        size="small"
                                        required
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...mainData.investments,
                                          ];
                                          newInvestments[index].financial_year =
                                            e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.financial_year}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        label="Enterprise Type"
                                        className="w-full"
                                        required
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...mainData.investments,
                                          ];
                                          newInvestments[
                                            index
                                          ].enterprise_type = e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.enterprise_type}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Written Down Value"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...mainData.investments,
                                          ];
                                          newInvestments[index].wdv =
                                            e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.wdv}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label=" Exclusion of cost"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...mainData.investments,
                                          ];
                                          newInvestments[
                                            index
                                          ].exclusion_of_cost = e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.exclusion_of_cost}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Net Investment in Plant and Machinery/Equipment"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...mainData.investments,
                                          ];
                                          newInvestments[index].net_investment =
                                            e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.net_investment}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Total Turnover"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...mainData.investments,
                                          ];
                                          newInvestments[index].total_turnover =
                                            e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.total_turnover}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Export Turnover"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...mainData.investments,
                                          ];
                                          newInvestments[
                                            index
                                          ].export_turnover = e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.export_turnover}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Net Turnover"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...mainData.investments,
                                          ];
                                          newInvestments[index].net_turnover =
                                            e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.net_turnover}
                                      />
                                    </div>
                                    <FormControlLabel
                                      label="Is ITR Filled?"
                                      control={
                                        <Checkbox
                                          checked={
                                            investment.is_itr_filled === "Yes"
                                          }
                                          onChange={() => {
                                            const newInvestments = [
                                              ...mainData.investments,
                                            ];
                                            newInvestments[
                                              index
                                            ].is_itr_filled =
                                              investment.is_itr_filled === "Yes"
                                                ? "No"
                                                : "Yes";
                                            setMaindata({
                                              ...mainData,
                                              investments: newInvestments,
                                            });
                                          }}
                                        />
                                      }
                                    />
                                    <div className="w-full">
                                      <TextField
                                        label="ITR Type"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newInvestments = [
                                            ...mainData.investments,
                                          ];
                                          newInvestments[index].itr_type =
                                            e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            investments: newInvestments,
                                          });
                                        }}
                                        value={investment.itr_type}
                                      />
                                    </div>
                                    <div className="w-full md:col-start-2">
                                      <Button
                                        onClick={() =>
                                          handleRemoveField(
                                            "investments",
                                            index
                                          )
                                        }
                                        variant="contained"
                                        color="error"
                                      >
                                        Remove
                                      </Button>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                            <div className="border-b border-gray-900/10 pb-12">
                              <h2 className="text-base font-semibold leading-7 text-gray-900">
                                Unit(s) Details
                                <Button onClick={() => handleAddField("units_with_files_verification")}>
                                  <PlusCircleIcon className="h-7 w-7" />
                                </Button>
                              </h2>
                              {mainData?.units_with_files_verification?.map((unit, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-3 relative pl-10"
                                  >
                                    <div className="absolute font-bold text-xl">
                                      {index + 1}
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Unit Name"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...mainData.units_with_files_verification];
                                          newUnits[index].name = e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.name}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Flat"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...mainData.units_with_files_verification];
                                          newUnits[index].flat = e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.flat}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Building"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...mainData.units_with_files_verification];
                                          newUnits[index].building =
                                            e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.building}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Village/Town"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...mainData.units_with_files_verification];
                                          newUnits[index].village =
                                            e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.village}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Block"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...mainData.units_with_files_verification];
                                          newUnits[index].block =
                                            e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.block}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Road"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...mainData.units_with_files_verification];
                                          newUnits[index].road = e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.road}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="City"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...mainData.units_with_files_verification];
                                          newUnits[index].city = e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.city}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="Pin"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...mainData.units_with_files_verification];
                                          newUnits[index].pin = e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.pin}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="State"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...mainData.units_with_files_verification];
                                          newUnits[index].state =
                                            e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.state}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        required
                                        label="District"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          const newUnits = [...mainData.units_with_files_verification];
                                          newUnits[index].district =
                                            e.target.value;
                                          setMaindata({
                                            ...mainData,
                                            units: newUnits,
                                          });
                                        }}
                                        value={unit.district}
                                      />
                                    </div>
                                    <div className="w-full md:col-start-2">
                                      <Button
                                        onClick={() =>
                                          handleRemoveField("units", index)
                                        }
                                        variant="contained"
                                        color="error"
                                      >
                                        Remove
                                      </Button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                              Official address of Enterprise
                            </h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-3">
                              <div className="w-full">
                                <TextField
                                  label="Flat/Door/Block No"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setMaindata({
                                      ...mainData,
                                      official_address: {
                                        ...mainData.official_address,
                                        flat: e.target.value,
                                      },
                                    });
                                  }}
                                  value={
                                    mainData?.official_address?.flat
                                      ? mainData?.official_address?.flat
                                      : ""
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Village/Town"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setMaindata({
                                      ...mainData,
                                      official_address: {
                                        ...mainData.official_address,
                                        village: e.target.value,
                                      },
                                    });
                                  }}
                                  value={
                                    mainData?.official_address?.village
                                      ? mainData?.official_address?.village
                                      : ""
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Road/Street/Lane"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setMaindata({
                                      ...mainData,
                                      official_address: {
                                        ...mainData.official_address,
                                        road: e.target.value,
                                      },
                                    });
                                  }}
                                  value={
                                    mainData?.official_address?.road
                                      ? mainData?.official_address?.road
                                      : ""
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="State"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setMaindata({
                                      ...mainData,
                                      official_address: {
                                        ...mainData.official_address,
                                        state: e.target.value,
                                      },
                                    });
                                  }}
                                  value={
                                    mainData?.official_address?.state
                                      ? mainData?.official_address?.state
                                      : ""
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Mobile"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setMaindata({
                                      ...mainData,
                                      official_address: {
                                        ...mainData.official_address,
                                        mobile: e.target.value,
                                      },
                                    });
                                  }}
                                  value={
                                    mainData?.official_address?.mobile
                                      ? mainData?.official_address?.mobile
                                      : ""
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Name of Premises/Building"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setMaindata({
                                      ...mainData,
                                      official_address: {
                                        ...mainData.official_address,
                                        name: e.target.value,
                                      },
                                    });
                                  }}
                                  value={
                                    mainData?.official_address?.name
                                      ? mainData?.official_address?.name
                                      : ""
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Block"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setMaindata({
                                      ...mainData,
                                      official_address: {
                                        ...mainData.official_address,
                                        block: e.target.value,
                                      },
                                    });
                                  }}
                                  value={
                                    mainData?.official_address?.block
                                      ? mainData?.official_address?.block
                                      : ""
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="City"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setMaindata({
                                      ...mainData,
                                      official_address: {
                                        ...mainData.official_address,
                                        city: e.target.value,
                                      },
                                    });
                                  }}
                                  value={
                                    mainData?.official_address?.city
                                      ? mainData?.official_address?.city
                                      : ""
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="District"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setMaindata({
                                      ...mainData,
                                      official_address: {
                                        ...mainData.official_address,
                                        district: e.target.value,
                                      },
                                    });
                                  }}
                                  value={
                                    mainData?.official_address?.district
                                      ? mainData?.official_address?.district
                                      : ""
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Email"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setMaindata({
                                      ...mainData,
                                      official_address: {
                                        ...mainData.official_address,
                                        email: e.target.value,
                                      },
                                    });
                                  }}
                                  value={
                                    mainData?.official_address?.email
                                      ? mainData?.official_address?.email
                                      : ""
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                              National Industry Classification Code(S)
                              <Button onClick={() => handleAddField("nics")}>
                                <PlusCircleIcon className="h-7 w-7" />
                              </Button>
                            </h2>
                            {mainData?.nics?.map((nic, index) => {
                              return (
                                <div
                                  key={index}
                                  className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-3 pl-10 relative"
                                >
                                  <div className="absolute text-xl font-bold">
                                    {index + 1}
                                  </div>

                                  <div className="w-full">
                                    <TextField
                                      label="Nic 5 Digit"
                                      className="w-full"
                                      size="small"
                                      variant="outlined"
                                      onChange={(e) => {
                                        const newNics = [...mainData.nics];
                                        newNics[index].nic_id = e.target.value;
                                        setMaindata({
                                          ...mainData,
                                          nics: newNics,
                                        });
                                      }}
                                      value={nic.nic_id}
                                    />
                                  </div>

                                  <div className="w-full">
                                    <TextField
                                      label="Main Activity"
                                      className="w-full"
                                      size="small"
                                      variant="outlined"
                                      onChange={(e) => {
                                        const newNics = [...mainData.nics];
                                        newNics[index].activity =
                                          e.target.value;
                                        setMaindata({
                                          ...mainData,
                                          nics: newNics,
                                        });
                                      }}
                                      value={nic.activity}
                                    />
                                  </div>
                                  <div className="w-full md:col-span-2">
                                    <Button
                                      onClick={() =>
                                        handleRemoveField("nics", index)
                                      }
                                      variant="contained"
                                      color="error"
                                    >
                                      Remove
                                    </Button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="w-full mt-10 grid grid-cols-1">
                            <div
                              className="rounded-md px-2"
                              style={{ borderWidth: "1px" }}
                            >
                              <div>
                                <FormControlLabel
                                  className="w-full text-left"
                                  label={
                                    <Typography
                                      style={{
                                        fontSize: isMobile ? "10px" : "",
                                      }}
                                    >
                                      Are you interested to get registered on
                                      Government e-Market (GeM) Portal
                                    </Typography>
                                  }
                                  control={
                                    <Checkbox
                                      checked={mainData.gem_portal === 0}
                                      onChange={(e) => {
                                        setMaindata({
                                          ...mainData,
                                          gem_portal: e.target.checked ? 1 : 0,
                                        });
                                      }}
                                    />
                                  }
                                />
                              </div>
                              <div>
                                <FormControlLabel
                                  className="w-full text-left"
                                  label={
                                    <Typography
                                      style={{
                                        fontSize: isMobile ? "10px" : "",
                                      }}
                                    >
                                      Are you interested to get registered on
                                      TReDS Portals(one or more)
                                    </Typography>
                                  }
                                  control={
                                    <Checkbox
                                      checked={mainData.treds_portal === 1}
                                      onChange={(e) => {
                                        setMaindata({
                                          ...mainData,
                                          treds_portal: e.target.checked
                                            ? 1
                                            : 0,
                                        });
                                      }}
                                    />
                                  }
                                />
                              </div>
                              <div>
                                <FormControlLabel
                                  className="w-full"
                                  label="Are you interested to get registered on NCS
                                    Portals(one or more) "
                                  control={
                                    <Checkbox
                                      checked={mainData.ncs_portal === 1}
                                      onChange={(e) => {
                                        setMaindata({
                                          ...mainData,
                                          ncs_portal: e.target.checked ? 1 : 0,
                                        });
                                      }}
                                    />
                                  }
                                />
                              </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-5">
                              <TextField
                                label="DIC"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) =>
                                  setMaindata({
                                    ...mainData,
                                    dic: e.target.value,
                                  })
                                }
                                value={mainData?.dic ? mainData?.dic : ""}
                              />
                              <TextField
                                label="MSME DI"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) =>
                                  setMaindata({
                                    ...mainData,
                                    msme_di: e.target.value,
                                  })
                                }
                                value={
                                  mainData?.msme_di ? mainData?.msme_di : ""
                                }
                              />
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  value={
                                    mainData.date_of_registration
                                      ? new dayjs(mainData.date_of_registration)
                                      : null
                                  }
                                  format="DD-MM-YYYY"
                                  slotProps={{
                                    textField: { size: "small" },
                                  }}
                                  label="Udyam Registration Date"
                                  onChange={(newValue) =>
                                    setMaindata({
                                      ...mainData,
                                      date_of_registration: format(
                                        new Date(newValue.$d),
                                        "yyyy-MM-dd"
                                      ),
                                    })
                                  }
                                  className=""
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-3 mt-10">
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </div>
            </div>
          </form>
          <div className="py-3 rounded-md" style={{ borderWidth: "1px" }}>
            <div className="">
              {Boolean(mainData.remarks?.length) && (
                <div className="">Remarks</div>
              )}
              {mainData?.remarks?.map((remark, index) => {
                return (
                  <div
                    className="border w-full px-5 py-3 rounded-md"
                    key={remark.id}
                  >
                    <div
                      className="col-span-5  text-lg text-left"
                      style={{
                        fontSize: "15px",
                        width: "100% important",
                      }}
                    >
                      {remark.remarks}
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="text-xs text-left">
                        {remark.user?.name}
                      </div>
                      <div className="text-xs text-right">
                        {formatDate(
                          new Date(remark.created_at),
                          "dd-MM-yy hh:mm a"
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="">
              {Boolean(mainData.verifies?.length) && (
                <div className="text-center underline-offset-4 font-bold underline">
                  Verification Details
                </div>
              )}
              {mainData?.verifies?.map((verify, index) => {
                return (
                  <div
                    key={verify.id}
                    className="p-2 grid md:grid-cols-4 mt-4"
                    style={{ borderTopWidth: "1px", borderBottomWidth: "1px" }}
                  >
                    <div className="">
                      <div className="md:text-center">
                        Status :
                        {verify.status === "m_and_e"
                          ? " M&E"
                          : verify.status.replaceAll("_", " ").toUpperCase()}
                      </div>
                    </div>
                    {verify?.user?.name && (
                      <div
                        className="md:text-center"
                        style={{ borderLeftWidth: !isMobile ? "1px" : "0px" }}
                      >
                        Verification Staff : {verify.user?.name}
                      </div>
                    )}
                    {verify?.district_user?.name && (
                      <div
                        className="md:text-center"
                        style={{ borderLeftWidth: !isMobile ? "1px" : "0px" }}
                      >
                        District User : {verify.district_user?.name}
                      </div>
                    )}
                    {verify?.m_and_e_user?.name && (
                      <div
                        className="md:text-center"
                        style={{ borderLeftWidth: !isMobile ? "1px" : "0px" }}
                      >
                        M&E : {verify.m_and_e_user?.name}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>{" "}
        </div>
      )}
    </Authenticated>
  );
}
